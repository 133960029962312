"use strict"

declare const lightGallery: any
import * as bootstrap from "bootstrap"


// See: https://stackoverflow.com/questions/9899372/pure-javascript-equivalent-of-jquerys-ready-how-to-call-a-function-when-t #}
function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1)
    } else {
        document.addEventListener("DOMContentLoaded", fn)
    }
}


// Sprachauswahl
async function initLanguageSelection() {

    // Aktuelle Sprache ermitteln (aus dem HEAD)
    const LANGUAGE = document.querySelector("html").lang
    const languageSelection = document.querySelector("#languageSelection") as HTMLSelectElement

    if (!languageSelection) {
        return
    }

    // Aktuelle Sprache anzeigen
    languageSelection.value = LANGUAGE

    // Auf Sprachänderungen horchen
    languageSelection.addEventListener("change", (event) => {
        const target = event.target as any
        const selectedLanguage = target.value
        const url = new URL(location.href)

        let hostname = url.hostname

        // Eventuell vorhandene Sprache (z.B. "de." aus Hostname entfernen
        if (hostname.startsWith(`${LANGUAGE}.`)) {
            hostname = hostname.substring(`${LANGUAGE}.`.length)
        }

        // Gewählte Sprache vor den Hostname stellen
        url.hostname = `${selectedLanguage}.${hostname}`

        // Zur neuen Sprach-URL weiterleiten
        location.href = url.toString()

    })
}


async function initLightgallery() {
    const divElements = document.getElementsByClassName("lightgallery")
    for (let i = 0; i < divElements.length; i++) {
        const divElement: Element = divElements[i]

        // Lightgallery aktivieren
        lightGallery(divElement)

        // Wenn Thumbnail, dann "_thumbnail" beim Ziel entfernen
        const aElement: HTMLAnchorElement = divElement.querySelector("a")
        let href = aElement.href
        if (href.includes("_thumbnail")) {
            href = href.replace("_thumbnail", "")
            aElement.href = href
        }
    }
}


async function initTooltips() {
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let _ = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
}


(async function () {

    // $document.ready()
    docReady(async () => {
        // Sprachauswahl
        await initLanguageSelection()
        // Lightgallery
        await initLightgallery()
        // Tooltips
        await initTooltips()
    })


    // Highlight aktivieren
    // import hljs from 'highlight.js'  // https://github.com/highlightjs/highlight.js
    // hljs.highlightAll()

})()



